@import url("https://fonts.googleapis.com/css?family=Catamaran");
@import url("https://fonts.googleapis.com/css?family=Prompt");

:root {
  --menu-height: 59px;
  --base-padding: 32px;
  --banner-padding: 3px;
  --sidemenu-width: 352px;

  --secondary-color: #9c9cfd;
  --muted-color: #566170;
  --highlight-color: #ff6633;
  --primary: #982932;
  --primary-dark: #982932;
  --bs-primary: #982932;
  --bs-primary-rgb: 168, 28, 44;
  --bs-dark-rgb: 29, 38, 52;
  --highlight-color-light: #f3985c;
  --light-background: #e6ebed;
  --light-grey: #f1f1f1;
  --medium-background: #4c5664;
  --dark-background: #1d2634;
  --background: white;

  --input-background: #f5f2f4;

  --tiles-per-line: 3;

  --base-radius: 4px;

  --collapse-transform-duration: 0.5s;
  --container-width: 900px;

  --bs-info-rgb: 156, 156, 253;
  /* secondary - #9c9cfd */
}

.light-background {
  background: var(--light-background);
}

.fit-content {
  height: fit-content;
}

.radius {
  border-radius: var(--base-radius);
}

.muted-color {
  color: var(--muted-color);
}

.highlight-color {
  color: var(--primary);
}

.primary-color {
  color: var(--primary);
}


.border-primary-color {
  border-color: var(--primary) !important;
}

#root {
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.form-control:focus {
  border-color: var(--highlight-color-light);
  box-shadow: 0 0 0 0.25rem #f3985c5c;
}


html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Inter", Arial!important;
  box-sizing: border-box;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}

a:-moz-any-link {
  color: inherit;
  text-decoration: none;
}

a:any-link {
  color: inherit;
  text-decoration: none;
}

button,
input,
select {
  color: inherit;
  font: inherit;
}

.fs-7 {
  font-size: 0.875rem !important;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.h-100vh {
  height: 100vh;
}

.min-h-100vh {
  min-height: 100vh;
}

select {
  font-size: clamp(0.8rem, 1vw, 1rem);
}

.active_disabled_arrow_box {
  background: #5f5f5f;
}

.active_disabled_arrow_box:after {
  border-top-color: #5f5f5f;
}

.active_arrow_box {
  background: #f2711c;
}

.active_arrow_box:after {
  border-top-color: #f2711c;
}

.gap {
  gap: 1em;
}

/* http://www.cssarrowplease.com/ */
.arrow_box {
  position: relative;
  padding: 8px;
  margin-bottom: 24px;
  background: lightgrey;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.arrow_box:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: lightgrey;
  border-width: 13px;
  margin-left: -13px;
}

.DraftEditor-editorContainer {
  font-family: "Catamaran", sans-serif;
}

.modern-theme {
  font-family: "Catamaran, sans-serif";
}

.mui-fixed {
  overflow: auto;
}

.react-add-to-calendar__dropdown ul {
  padding: 0 !important;
}

.react-add-to-calendar__dropdown {
  z-index: 10 !important;
}

.react-add-to-calendar__button {
  padding: 8px !important;
  font-size: 14px !important;
  background: white !important;
}

.react-calendar {
  font: inherit !important;
}

.react-calendar__tile--active {
  background: none !important;
  color: black !important;
}

.react-calendar__tile {
  position: relative;
  border: 2px solid transparent !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.rte-button {
  border: none;
}

.pdf-controlls-container {
  display: flex !important;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.pdf-controlls-container>div {
  top: 55px;
  margin: 10px;
  text-align: center;
  position: fixed;
}

.pdf-controlls-container>div:first-child {
  display: block;
  left: 47%;
}

.pdf-controlls-container>div:nth-child(2) {
  display: block;
  left: 49%;
}

.pdf-controlls-container>div:last-child {
  display: block;
  left: 51%;
}

.pdf-controlls-container>div>i {
  font-style: normal;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  cursor: pointer;
}

.pdf-canvas {
  display: flex !important;
  justify-content: center;
}

.mside-1 {
  margin: 0 1em;
}

.bold {
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.col,
.flex-1 {
  flex: 1
}

.flex-column {
  display: flex;
  flex-direction: column;
}


.btn:focus {
  box-shadow: none;
}



.pg-viewer-wrapper {
  border: 1px solid lightgrey;
  overflow-y: auto !important;
  width: 100%;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.input {
  background: var(--input-background);
}

.highlight-active:hover {
  background: var(--highlight-color-light);
  font-weight: bold;
}

.ql-container {
  font-size: inherit !important;
}

.ql-editor img {
  max-width: 200px;
  max-height: 200px;
}

.input-container {
  border-radius: 4px;
  box-shadow: inset 0px 1px 2px rgba(29, 38, 52, 0.2);
  background: var(--input-background);
  width: 100%;
}


.custom-tooltip {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1080px) {
  :root {
    --tiles-per-line: 2;
  }
  html,
  body{
    font-size: 1rem;
  }
}

@media screen and (max-width: 720px) {
  :root {
    --tiles-per-line: 1;
  }
  .fs-7 {
    font-size: 1rem !important;
  }
  .fs-8 {
    font-size: 0.9rem !important;
  }
}



@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .avoid-page-break {
    break-inside: avoid;
  }


  .print-no-scroll {
    overflow: hidden !important;
  }
}

.beaver-chat-container {
  display: flex;
  flex-direction: column;
  border-color: var(--primary);
}

.beaver-chat-input {
  width: unset;
}

.beaver-chat-button {
  box-sizing: border-box;
  border-radius: 20px;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:disabled {
  background-color: var(--muted-color);
  border-color: var(--muted-color);
}